import { Component } from '@angular/core';

import { OasGeneratorRequest } from './models/oas-generator.model';
import { EntityVersionAttribute } from './models/curated-Api/curated-api-attribute.model';
import { EntityVersion } from './models/curated-Api/curated-api-entity-version.model';
import { PageComponent } from "./page.component";

@Component({
    selector: 'app-oas-generator',
    templateUrl: './oas-generator.component.html',
    styleUrls: ['./oas-generator.component.scss'],
})
export class OasGeneratorComponent extends PageComponent {
    selectedAttributesMap: Map<
        EntityVersion,
        EntityVersionAttribute[] | undefined
    > = new Map<EntityVersion, EntityVersionAttribute[] | undefined>();
    inputModel: OasGeneratorRequest = new OasGeneratorRequest();
}
