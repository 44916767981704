<section class="full-size-section">
    <div class="row">
        <div class="col"></div>
        <div class="col-10"></div>
        <div class="col"></div>
    </div>
    <div class="row">
        <div class="col">
        </div>
        <div class="col-10">
            <div class="card" style="width: 70vw; margin: 20px;">
                <div class="card-header">
                    OAS:Preview
                  <input class="btn btn-primary float-right" type="submit" value="Download" (click)="downloadClicked()" *ngIf="oasgenRequest.selectedEntityVersions.length>0">
                  </div>
                <div class="card-body">
                    <div style="margin: 0 auto;">
                        <app-editor [(text)]="yamlInputData" mode="yaml"></app-editor>
                    </div>
                    <hr>
                     <input *ngIf="oasgenRequest.selectedEntityVersions.length>3" class="btn btn-primary float-right" type="submit" value="Download" (click)="downloadClicked()">
                </div>
            </div>
        </div>
        <div class="col">
        </div>
    </div>
    <div class="row">
        <div class="col"></div>
        <div class="col-10"></div>
        <div class="col"></div>
    </div>
</section>
