export class InfoViewModel {
    globalApiId!: number;
    name!: string;
    description!: string;
    version!: string;
    versions!: Version[];
    contactEmail!: string;
    existsInMarketplace!: boolean;
    supportTeam!: string;

    constructor(init?: Partial<InfoViewModel>) {
        Object.assign(this, init);
    }
}

export class Version{
    version!: string;
    supportTeam!: SupportTeam;
    globalApiVersionId!: string;
}

export class SupportTeam{
    supportTeamEmail!: string;
    supportTeamName!: string;
}