<app-loading-overlay [isBusy]="isBusy" [displayMessage]="loadingMessage"></app-loading-overlay>
<form *ngIf="dynamicForm" [formGroup]="dynamicForm" (ngSubmit)="onSubmit()">
    <ng-container *ngIf="loadedtemplate!=undefined ">
        <ng-container
            *ngIf="loadedtemplate.sections!=undefined && loadedtemplate.sections!=null && loadedtemplate.sections.length>0">
            <ng-container *ngFor="let section of loadedtemplate.sections;">
                <div class="row">
                    <div class="col-12">
                        <h3 class="lowercase">{{section.displayName}}</h3>
                    </div>
                    <ng-container *ngIf="section.templateAttributes!=undefined && section.templateAttributes.length>0">

                        <div *ngFor="let attribute of section.templateAttributes;let i = index" class="col-6"
                            formGroupName={{section.name}}>
                            <ng-container *ngIf="attribute.type=='number' ||attribute.type=='string'">
                                <div>
                                    <div class="form-group">
                                        <label for={{attribute.name}}>{{attribute.displayName}}</label>
                                        <input [type]="attribute.type" [formControlName]="attribute.name"
                                            [placeholder]="attribute.placeHolder" class="form-control">
                                        <span class="small-font" *ngIf="attribute.helpText!=''">
                                            {{attribute.helpText}}
                                        </span>
                                    </div>
                                    <span
                                        *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.invalid && dynamicForm.get(section.name +'.' +attribute.name)?.touched"
                                        class="text-danger">
                                        <span
                                            *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.errors?.required">
                                            {{attribute.displayName}} is Required
                                        </span>

                                        <span
                                            *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.errors?.pattern">
                                            {{attribute.onPatternErrorMessage}}
                                        </span>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="attribute.type=='enum' ||attribute.subType=='select'">
                                <div>
                                    <div class="form-group">
                                        <label for={{attribute.name}}>{{attribute.displayName}}</label>
                                        <select [formControlName]="attribute.name " class="form-control">
                                            <option value="">{{attribute.placeHolder}}</option>
                                            <option *ngFor="let option of attribute.valueList?.split(',')"
                                                [value]="option">{{option}}</option>
                                        </select>

                                    </div>
                                    <span
                                        *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.invalid && dynamicForm.get(section.name +'.' +attribute.name)?.touched"
                                        class="text-danger">
                                        <span
                                            *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.errors?.required">
                                            {{attribute.displayName}} is Required
                                        </span>
                                    </span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="attribute.type=='bool' ||attribute.subType=='checkbox'">
                                <div>
                                    <div class="form-check">
                                        <input type="checkbox" [formControlName]="attribute.name "
                                            class="form-check-input">
                                        <label class="form-check-label"
                                            for={{attribute.name}}>{{attribute.displayName}}</label>
                                    </div>
                                    <span
                                        *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.invalid && dynamicForm.get(section.name +'.' +attribute.name)?.touched"
                                        class="text-danger">
                                        <span
                                            *ngIf="dynamicForm.get(section.name +'.' +attribute.name)?.errors?.required">
                                            {{attribute.displayName}} is Required
                                        </span>
                                    </span>
                                </div>
                            </ng-container>

                        </div>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <hr>
        <div *ngIf="errorMessage" class="row">
            <div class="alert alert-danger w-100" role="alert">
                <span [textContent]="errorMessage"></span>
            </div>
        </div>
        <div class="row">
            <div class="w-100">
                <button type="submit" class="btn btn-primary float-right">Submit</button>
            </div>
        </div>
    </ng-container>
</form>