import { Component } from "@angular/core";
import { OASGeneratorService } from "../../services/curated-api-service";
import { OasGeneratorRequestBuilderService } from "../../services/stepper-data-service";
import { OasGeneratorRequest } from "src/app/oas-generator/models/oas-generator.model";

@Component({
    selector: 'app-step3-oas-builder',
    templateUrl: './step3-oas-builder.component.html',
    styleUrls: ['./step3-oas-builder.component.scss']
})

export class Step3OasBuilderComponent {

    oasgenRequest!: OasGeneratorRequest;
    showTag: boolean = true;
    tags!: string[];

    constructor(
        private _oasGenService: OASGeneratorService,
        private _requestBuilder: OasGeneratorRequestBuilderService
        ) {
            _requestBuilder.getData().subscribe((res) => {
                this.oasgenRequest = res;
            });
        }
}
