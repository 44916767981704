<main class="container">
    <section class="w-100">
        <div class="row"  *ngIf=" oasgenRequest.selectedEntityVersions.length>0">
            <div class="col col-12">
                <h3 class="title" (click)="show()">
                    Security
                    <span *ngIf="!showSecurity" class="icon-expand-plus button-right" ></span>
                    <span *ngIf="showSecurity" class="icon-expand-minus button-right"></span>
                </h3>
                <hr />
                <form [formGroup]="oasBuilderSecurityForm">
                    <div formArrayName="security">
                        <div class="security" *ngFor="let sec of security().controls; let i=index;" [formGroupName]="i">
                            <div class="button-right">
                                <span class="icon-remove" (click)="removeSecurity(i)"></span>
                            </div>
                            <div class="row scheme">
                                <div class="form-group col-md-12 mt-3">
                                    <label for="securityInput">
                                        Security Scheme
                                    </label>
                                    <select id="securityInput" class="form-control"
                                    formControlName="security">
                                    <option value="">Select security scheme</option>
                                    <option *ngFor="let securityType of securityOptions" [value]="securityType">
                                        {{ securityType }}
                                    </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="isOauth(i)">
                                <h4 class="title">OAuth Flows</h4>
                                <hr/>
                                <div class="row">
                                    <label for="flowInput">
                                        Flow
                                    </label>
                                    <select id="flowInput" class="form-control"
                                    formControlName="flow">
                                    <option value="">Select flow</option>
                                    <option *ngFor="let type of oauthOptions" [value]="type">
                                        {{ type }}
                                    </option>
                                    </select>
                                </div>
                            </div>
                            <div *ngIf="isApiKey(i)">
                                <h4 class="title">ApiKey</h4>
                                <hr/>
                                <div class="row">
                                    <label for="apiKeyTypeInput">
                                        Options
                                    </label>
                                    <select id="apiKeyTypeInput" class="form-control"
                                    formControlName="apiKeyType">
                                    <option value="">Select api key type</option>
                                    <option *ngFor="let type of apiKeyOptions" [value]="type">
                                        {{ type }}
                                    </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <button mat-raised-button class="button-right mat-focus-indicator mat-button-base mat-primary ng-star-inserted" (click)="addSecurity()">
                            add
                            <span class="icon-expand-add icon-expand-plus"></span>
                        </button>
                        </div>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    </section>
</main>
