<main class="container">
    <section class="w-100">
        <div class="row">
            <div class="col col-12">
                <h3 class="title" (click)="show()">
                    Tags
                    <span *ngIf="!showTag" class="icon-expand-plus button-right"></span>
                    <span *ngIf="showTag" class="icon-expand-minus button-right"></span>
                </h3>
                <hr />
                <form *ngIf="showTag" [formGroup]="oasBuilderTagForm">
                    <ng-container formArrayName="rows">
                    <div class="row form-group align-items-end" *ngFor="let control of dynamicRows.controls; let i=index;" [formGroupName]="i">
                        <div class="col-md-6">
                            <label for="tagNameInput">
                                <span class="text-danger">*</span>
                                Name
                            </label>
                            <input id="tagNameInput" type="text" class="form-control"
                                formControlName="name" placeholder="ex: tag" />
                        </div>
                        <div class="col-md-6 description">
                            <div class="description">
                                <label for="tagDescriptionInput" class="inline">
                                    <span class="text-danger">*</span>
                                    Description
                                </label>
                            </div>
                            <div class="description-input">
                                <input id="tagDescriptionInput" type="text" class="inline form-control"
                                formControlName="description" placeholder="ex: description here" />
                            </div>
                            <div class="inline-flex" (click)="removeTag(i)">
                                <span class="icon-remove inline"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <button mat-raised-button class="button-right mat-focus-indicator  mat-button-base mat-primary ng-star-inserted" (click)="addTag()">
                            add
                            <span class="icon-expand-add icon-expand-plus"></span>
                        </button>
                        </div>
                    </div>
                </ng-container>
                </form>
                <hr />
            </div>
        </div>
    </section>
</main>
