import { Injectable } from "@angular/core";
import { CalAngularService } from "@cvx/cal-angular";
import axios from "axios";
import { Observable } from "rxjs";
import { AppConfig } from "../app-config.service";
import { Entity } from "src/app/oas-generator/models/curated-Api/curated-api-entity.model";
import { EntityVersion } from "src/app/oas-generator/models/curated-Api/curated-api-entity-version.model";
import { EntityVersionAttribute } from "src/app/oas-generator/models/curated-Api/curated-api-attribute.model";
import { Api } from "src/app/oas-generator/models/curated-Api/curated-api-api.model";
import { Version } from "src/app/oas-generator/models/curated-Api/curated-api-version.model";

@Injectable()
export class OASGeneratorService {
    constructor(
        private authService: CalAngularService) {
    }

    async getApi(id: string): Promise<Api> {
        const uri: string = AppConfig.settings.apisUri+id;
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<Api>(uri, {
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }
    
    async getApiVersions(id: string): Promise<Version[]> {
        const uri: string = AppConfig.settings.apisUri+id+"/versions";
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<Version[]>(uri, {
            params:{
                id:id
            },
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }

    async getEntities(entityName: string): Promise<Entity[]> {
        const uri: string = AppConfig.settings.entitiesUri;
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<Entity[]>(uri, {
            params:{
                name:entityName
            },
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }

    async getEntityVersionsByEntityId(entityId: number): Promise<EntityVersion[]> {
        const uri: string = AppConfig.settings.entitiesUri+"/"+entityId+"/versions";
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<EntityVersion[]>(uri, {
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }

    async getEntityAttributesbyEntityVersionId(entityVersionId: number): Promise<EntityVersionAttribute[]> {
        const uri: string = AppConfig.settings.entityVersionsUri+entityVersionId+"/attributes";
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<EntityVersionAttribute[]>(uri, {
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }


    private authorizeAsync(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const scopes = [`${AppConfig.settings.clientId}/.default`];
                const authResult: Observable<string> = this.authService.getAADToken(scopes, false) as Observable<string>;
                authResult.subscribe(value => {
                    resolve(value);
                });
            } catch(err: any) {
                reject(err);
            }
        })
    }
}
