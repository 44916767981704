import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';
import { AppConfig } from '../../oas-generator/app-config.service';
import { Observable } from 'rxjs';
import axios from 'axios';
import { Template } from '../models/template.model';

@Injectable({
    providedIn: 'root'
})
export class FormService {

    constructor(private authService: CalAngularService) { }

    async getUIFromTemplate(template: string): Promise<Template> {
        const uri: string = AppConfig.settings.templatesUri + "/" + template;
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<Template>(uri, {
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }

    async getAllTemplates(): Promise<Template[]> {
        const uri: string = AppConfig.settings.templatesUri;
        const accessToken = await this.authorizeAsync();
        const response = await axios.get<Template[]>(uri, {
            headers:
            {
                authorization: `Bearer ${accessToken}`
            }
        });
        return response.data;
    }

    async getTemplateFiles(templateName: string, request: object): Promise<Blob> {
        const uri: string = AppConfig.settings.templatesUri + "/" + templateName + "/render";
        const accessToken = await this.authorizeAsync();
        const response = await axios.post(uri, request, {
            headers: {
                authorization: `Bearer ${accessToken}`
            },
            responseType: 'arraybuffer'
        });
        return new Blob([response.data], { type: 'application/zip' });
    }

    private authorizeAsync(): Promise<string> {
        return new Promise((resolve, reject) => {
            try {
                const scopes = [`${AppConfig.settings.clientId}/.default`];
                const authResult: Observable<string> = this.authService.getAADToken(scopes, false) as Observable<string>;
                authResult.subscribe(value => {
                    resolve(value);
                });
            } catch (err: any) {
                reject(err);
            }
        })
    }
}
