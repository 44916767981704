import { Component, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { FormService } from '../services/form-generator.service';
import { Template, PlaybookGeneratorRequest } from '../models/template.model';

@Component({
  selector: 'app-load-form',
  templateUrl: './load-form.component.html',
  styleUrls: ['./load-form.component.scss']
})

export class LoadFormComponent {
    @Input() template! :string;
    dynamicForm!: FormGroup;
    loadedtemplate!:Template;
    isBusy: boolean = true;
    loadingMessage: string = "Loading";
    errorMessage: string = '';

    constructor(private formBuilder: FormBuilder, public formService: FormService) {
    }

    async ngOnChanges() {
      this.isBusy = true;
      this.loadingMessage = "Loading Template";
      let formSection: Record<string, any>={};
      if(this.template!=undefined || this.template!=null)
      {
        this.loadedtemplate= await this.formService.getUIFromTemplate(this.template);
        if(this.loadedtemplate!=undefined)
        {
            if(this.loadedtemplate.sections!=undefined && this.loadedtemplate.sections?.length>0)
            {
                this.loadedtemplate.sections.forEach(section=>
                    {

                        let formGroup: Record<string, any> = {};

                        if(section.templateAttributes!=undefined && section.templateAttributes.length>0)
                        {
                            section.templateAttributes.forEach(attribute=>{

                                let controlValidators: Validators[] = [];
                                if (attribute.required!=null && attribute.required===true)
                                {
                                    controlValidators.push(Validators.required);
                                }
                                if (attribute.pattern!=null)
                                {
                                    controlValidators.push(Validators.pattern(attribute.pattern));
                                }
                                formGroup[attribute.name] =['', controlValidators];
                            })
                        }
                        formSection[section.name]=this.formBuilder.group(formGroup);
                    }
                )
                this.dynamicForm = this.formBuilder.group(formSection);
            }
        }
      }
      this.isBusy = false;
    }

   async onSubmit() {
        if (this.dynamicForm.valid) {
            try {
                this.errorMessage = '';
                this.isBusy = true;
                this.loadingMessage = "Submitting form"
                let request = new PlaybookGeneratorRequest();
                request.data = this.dynamicForm.value;
                const result = await this.formService.getTemplateFiles(this.template, request);
                const resultUrl = window.URL.createObjectURL(result);
                const resultLink = document.createElement('a');
                resultLink.href = resultUrl;
                resultLink.setAttribute('download', 'Templates_'+ this.template+'_OutPut.zip');
                document.body.appendChild(resultLink);
                resultLink.click();
                document.body.removeChild(resultLink);
            } catch(err) {
                this.errorMessage = "Something went wrong, please try again later. If the problem persist, please contact the technical support team."
                throw err;
            } finally {
                this.isBusy = false;
            }
        } else {
          this.validateAllFormFields(this.dynamicForm);
        }
      }

      validateAllFormFields(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
          const control = formGroup.get(field);
          if (control instanceof FormControl) {
            control.markAsTouched({ onlySelf: true });
          } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
          }
        });
      }

    getErrorMessage(control: any) {
      const formControl = this.dynamicForm.get(control.name);

      if (!formControl) {
        return '';
      }

      for (let validation of control.validations) {
        if (formControl.hasError(validation.name)) {
          return validation.message;
        }
      }

      return '';
    }

    makeIntoColClass(colSize: number): string{
      return "col-md-"+colSize;
    }
}
