<main class="container">
    <section class="w-100">
        <div class="row">
            <div class="col col-12">
                <h3 class="title" (click)="show()">
                    Information
                    <span *ngIf="!showInfo" class="icon-chevron-down"></span>
                    <span *ngIf="showInfo" class="icon-chevron-up"></span>
                </h3>
                <hr />
                <form class="form-group" *ngIf="showInfo" [formGroup]="oasBuilderInfoForm">
                    <div class="row">
                        <div class="col-md-3">
                            <label for="marketplaceInput">
                                <span class="text-danger">*</span>
                                Exists In Marketplace?
                            </label>
                            <select id="marketplaceInput" class="form-control" (change)="onExistsSubmit($event)" formControlName="existsInMarketplace">
                                <option value="">Select</option>
                                <option *ngFor="let item of existsInMarketplaceDropdown">
                                    {{ item }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-3">
                            <label for="apiIdInput">
                                <span *ngIf="existsInMarketplace" class="text-danger">*</span>
                                Global API Id
                            </label>
                            <input *ngIf="!existsInMarketplace" id="apiIdInput" type="text" class="form-control" [readOnly]=!existsInMarketplace
                            formControlName="apiId" placeholder="ex: 100" (keyup.enter)="onSubmit($event)"/>
                            <input *ngIf="existsInMarketplace" id="apiIdInput" type="text" class="form-control" [readOnly]=!existsInMarketplace
                            formControlName="apiId" placeholder="ex: 100" (keyup.enter)="onSubmit($event)"/>
                        </div>
                        <div class="col-md-2" *ngIf="existsInMarketplace">
                            <label for="versionInput">
                                <span class="text-danger">*</span>
                                Version
                                <div *ngIf="isLoading" class="loader"></div>
                            </label>
                            <select id="versionInput" class="form-control"
                            formControlName="version" (change)="onVersionSubmit($event)">
                                <option value="">Select version</option>
                                <option *ngFor="let version of versionOptions" [value]="version.globalApiVersionId">
                                    {{ version.version }}
                                </option>
                            </select>
                        </div>
                        <div class="col-md-4" *ngIf="newVersion">
                            <label for="versionInput">
                                <span class="text-danger">*</span>
                                Version
                            </label>
                            <input id="versionInput" type="text" class="form-control" formControlName="version" placeholder="ex: 1.0.0"/>
                        </div>
                        <div class="col-md-6" *ngIf="!existsInMarketplace">
                            <label for="versionInput">
                                <span class="text-danger">*</span>
                                Version
                            </label>
                            <input id="versionInput" type="text" class="form-control" formControlName="version" placeholder="ex: 1.0.0"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="descriptionInput">
                                Description
                            </label>
                            <input id="descriptionInput" type="text" class="form-control"
                                formControlName="description" placeholder="ex: description" />
                        </div>
                        <div class="col-md-6">
                            <label for="nameInput">
                                <span class="text-danger">*</span>
                                Name
                                <div *ngIf="isLoading" class="loader"></div>
                            </label>
                            <input *ngIf="existsInMarketplace" id="nameInput" type="text" class="form-control" [readOnly]=existsInMarketplace
                                formControlName="name" placeholder="{{apiInfoModel.name}}" />
                            <input *ngIf="!existsInMarketplace" id="nameInput" type="text" class="form-control" [readOnly]=existsInMarketplace
                                formControlName="name" placeholder="ex: name" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <label for="supportTeamInput">
                                <span class="text-danger">*</span>
                                Support Group
                                <div *ngIf="isLoadingSupport" class="loader"></div>
                            </label>
                            <input *ngIf="versionSelected&&existsInMarketplace&&!newVersion&&!isLoadingSupport" id="supportTeamInput" type="text" class="form-control" [readOnly]=versionSelected&&existsInMarketplace
                                formControlName="supportTeam" placeholder="{{versionModel.supportTeam.supportTeamName}}" />
                            <input *ngIf="!versionSelected||!existsInMarketplace||newVersion||isLoadingSupport" id="supportTeamInput" type="text" class="form-control" [readOnly]=versionSelected&&existsInMarketplace
                                formControlName="supportTeam" placeholder="ex: Support Team" />
                        </div>
                        <div class="col-md-6">
                            <label for="contactEmailInput">
                                <span class="text-danger">*</span>
                                Contact Email
                                <div *ngIf="isLoadingSupport" class="loader"></div>
                            </label>
                            <input *ngIf="versionSelected&&existsInMarketplace&&!newVersion&&!isLoadingSupport" id="contactEmailInput" type="text" class="form-control" [readOnly]=versionSelected&&existsInMarketplace
                                formControlName="contactEmail" placeholder="{{versionModel.supportTeam.supportTeamEmail}}" />
                            <input *ngIf="!versionSelected||!existsInMarketplace||newVersion||isLoadingSupport" id="contactEmailInput" type="text" class="form-control" [readOnly]=versionSelected&&existsInMarketplace
                                formControlName="contactEmail" placeholder="ex: contact@chevron.com" />
                        </div>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    </section>
</main>