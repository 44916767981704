import { NgModule } from '@angular/core';
import { RouterModule, Route } from '@angular/router';
import { CalGuardService } from '@cvx/cal-angular';
import { NextPageRoute, NextPageService } from '@cvx/nextpage';
import { HomeComponent } from './home/home.component';
import { OasGeneratorComponent } from './oas-generator/oas-generator.component';
import { FormGenerateComponent } from './playbook-generator/form-generate.component';
export const routes: NextPageRoute[] = [
    {
        path: 'home',
        title: 'home',
        redirectTo: ''
    },
    {
        path: 'playbook',
        title: 'playbook',
        component: FormGenerateComponent,
        pathMatch: 'full',
        canActivate: [CalGuardService]
    },
    {
        path: 'oasgen',
        title: 'oas generator',
        component: OasGeneratorComponent,
        pathMatch: 'full',
        canActivate: [CalGuardService]
    },
    { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [CalGuardService] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes as Route[], { useHash: false })],
    exports: [RouterModule]
})


export class AppRoutingModule {
    constructor(private NP: NextPageService) {
        this.NP.options.navigationRoutes = routes;
    }
}
