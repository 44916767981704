import { Component } from '@angular/core';
import { NavigationLocation, NextPageService, PageLayout, SearchType, SliderType } from '@cvx/nextpage';
import { AppConfig } from './oas-generator/app-config.service';
import { Router } from '@angular/router';
import { CalAngularService } from '@cvx/cal-angular';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    Title = AppConfig.settings.appTitle;
    NavigationLocation = NavigationLocation;
    PageLayout = PageLayout;
    SearchType = SearchType;
    SliderType = SliderType;
    welcomeName = '';
    loggedIn = false;

    constructor(private NP: NextPageService, private router: Router,  private authService: CalAngularService) {
        this.NP.options.sitename = this.Title;
    }
}