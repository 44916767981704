export interface Template {
    id: number;
    name: string;
    displayName: string;
    sections: Section[]|null;
}

export interface Section {
    id: number;
    templateId: number;
    name: string;
    displayName: string;
    templateAttributes: Attribute[]|null;
}

export interface Attribute {
    id: number;
    sectionId: number;
    name: string;
    displayName: string;
    placeHolder: string | null;
    type: string;
    subType: string | null;
    helpText: string | null;
    required: boolean | null;
    pattern: string | null;
    onPatternErrorMessage: string | null;
    valueList:string | null;
}

export class PlaybookGeneratorRequest {
    data!: Record<string, any> ;
}
