import { Injectable } from '@angular/core';
import { IAppConfig } from './app-config';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class AppConfig {
    static settings: IAppConfig;
    constructor(private http: HttpClient) {}
    load() {
        const appConfFile = `assets/config.json`;
        return new Promise<void>((resolve, reject) => {
            // App Config
            lastValueFrom(this.http.get<IAppConfig>(appConfFile)).then((response) => {
                AppConfig.settings = response;
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${appConfFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}