import { TagViewModel } from "./oas-generator-tag.model";

export class PathViewModel{
    httpAction!: string;
    summary!: string;
    description!: string;
    operationId!: string;
    endpoint!: string;
    parameters: ParameterViewModel[] = [];
    tags: TagViewModel[] = [];
    
    constructor(init?: Partial<PathViewModel>) {
        Object.assign(this, init);
    }
}

export class ParameterViewModel{
    name!: string;
    in!: string;
    description!: string;
    required!: boolean;
    constructor(init?: Partial<ParameterViewModel>) {
        Object.assign(this, init);
    }
}

export class HttpAction {
    public readonly name!: string;
    public readonly value!: string;

    private constructor(init?: Partial<HttpAction>) {
        Object.assign(this, init);
    }

    public static readonly default: HttpAction = new HttpAction();
    public static readonly get: HttpAction = new HttpAction({
        name: 'Get',
        value: 'get'
    });
    public static readonly post: HttpAction = new HttpAction({
        name: 'Post',
        value: 'post'
    });
    public static readonly put: HttpAction = new HttpAction({
        name: 'Put',
        value: 'put'
    });
    public static readonly delete: HttpAction = new HttpAction({
        name: 'Delete',
        value: 'delete'
    });
    public static readonly patch: HttpAction = new HttpAction({
        name: 'Patch',
        value: 'patch'
    });
    public static readonly options: HttpAction[] = [ 
        HttpAction.get,
        HttpAction.post,
        HttpAction.put,
        HttpAction.delete,
        HttpAction.patch
    ];
}
