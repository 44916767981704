import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TagViewModel } from "src/app/oas-generator/models/oas-generator-tag.model";
import { OasGeneratorRequestBuilderService } from "src/app/oas-generator/services/stepper-data-service";
import { OasGeneratorRequest } from "src/app/oas-generator/models/oas-generator.model";

@Component({
    selector: 'tag',
    templateUrl: './tag.component.html',
    styleUrls: ['./tag.component.scss']
})

export class TagComponent{
    constructor(
        private fb: FormBuilder,
        private _requestBuilder: OasGeneratorRequestBuilderService) { 
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
        });
    }
    oasBuilderTagForm!: FormGroup;
    tagInfoModel!: TagViewModel;
    showTag: boolean = true;
    oasgenRequest!: OasGeneratorRequest;

    ngOnInit(): void{
        const model = new TagViewModel();
        const form: FormGroup = this.fb.group({
            rows: this.fb.array([]),
            name: [model.name, [Validators.required]],
            description: [model.description, [Validators.required]]
        });
        this.oasBuilderTagForm = form;
        this.tagInfoModel = model;

        this.onChanges();
    }

    get dynamicRows() {
        return this.oasBuilderTagForm.controls['rows'] as FormArray;
    }

    addTag(): void{
        this.dynamicRows.push(
            this.fb.group({
              name: this.fb.control(''),
              description: this.fb.control('')
            })
        );
    }

    removeTag(idx: number): void{
        this.dynamicRows.removeAt(idx);
    }

    show(): void {
        this.showTag= !this.showTag;
    }

    onChanges(){
        this.oasBuilderTagForm.valueChanges.subscribe(value => {
            const tagRows = this.dynamicRows.controls;
            let currentTagViewModels: TagViewModel[] = [];
            tagRows.forEach((tag: { value: { description: any; name: any; }; }) => currentTagViewModels.push({description: tag.value.description, name: tag.value.name}));
            this.oasgenRequest.tags = currentTagViewModels;
            this._requestBuilder.setData(this.oasgenRequest);
        });
    }
}
