<main class="container">
    <section id="sidebar" npSetLayout [pageLayout]="PageLayout.Layout39">
        <div class="row">
            <div class="col col-12">
                <h4 matTooltip="api toolkit">toolkit</h4>
                <ul class="arrows">
                    <li>
                        <span id="expand-button" matTooltip="api management" (click)="onClick()">api management</span>
                        <ul class="flush" *ngIf="openState">
                            <li>
                                <a href="https://go.chevron.com/apim-dev" target="_blank" rel="noopener">dev</a>
                            </li>
                            <li>
                                <a href="https://go.chevron.com/apim-stage" target="_blank" rel="noopener">stage</a>
                            </li>
                            <li>
                                <a href="https://go.chevron.com/apim-prod" target="_blank" rel="noopener">prod</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="https://go.chevron.com/apim" target="_blank" rel="noopener">api marketplace</a>
                    </li>
                    <li>
                        <a href="https://dev.azure.com/chevron/ITC-ITSD-DataAndAPIManagement/_git/ITC-ITSD-DataAndAPIManagement" target="_blank" rel="noopener">central api repo</a>
                    </li>
                    <li>
                        <a routerLink="playbook">playbook generator</a>
                    </li>
                    <li>
                        <a routerLink="oasgen">oas generator</a>
                    </li>
                    <li>
                        <a href="https://apps.powerapps.com/play/5d449c2b-fd22-4e7a-b8c8-cbe621d1ec64?tenantId=fd799da1-bfc1-4234-a91c-72b3a1cb9e26" target="_blank" rel="noopener">delete api utility</a>
                    </li>
                </ul>
                <h4 matTooltip="cdas resources">cdas resources</h4>
                <ul class="arrows">
                    <li>
                        <a href="https://cdas.azure.chevron.com/api-and-integration.wiki/API-Products/API-Management" target="_blank" rel="noopener">api management</a>
                    </li>
                    <li>
                        <a href="https://cdas.azure.chevron.com/api-and-integration.wiki/API-Products/API-Marketplace" target="_blank" rel="noopener">api marketplace</a>
                    </li>
                    <li>
                        <a href="https://cdas.azure.chevron.com/api-and-integration.wiki/API-Products/Central-API-Repo" target="_blank" rel="noopener">central api repo</a>
                    </li>
                    <li>
                        <a href="https://cdas.azure.chevron.com/api-and-integration.wiki/API-Products/Delete-API-Utility" target="_blank" rel="noopener">delete api utility</a>
                    </li>
                </ul>
            </div>
        </div>
    </section>
    <section id="main">
        <div class="row">
            <div class="col col-12">
                <h1>
                    <img class="icon" src="../../assets/img/icon.png" alt="Icon" /><br />
                    overview</h1>
                <p>
                    The Software Engineering toolkit is a growing collection of development tools, created and maintained by the API & Integration Accelerators team.<br/>
                    <br/>
                    The purpose of this toolkit is to help software engineers design, develop and deploy APIs and other integrations. Through the utilization of standards and templates,
                    with the overall goal of reducing the time it takes for development to occur.<br/>
                </p>
            </div>
        </div>
    </section>
</main>
