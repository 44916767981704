import { InfoViewModel } from "./oas-generator-info.model";
import { PathViewModel } from "./oas-generator-path.model";
import { SecurityViewModel } from "./oas-generator-security.model";
import { TagViewModel } from "./oas-generator-tag.model";
import { EntityVersionViewModel } from "./oas-generator-view.model";

export class OasGeneratorRequest {
    public selectedEntityVersions:EntityVersionViewModel[]= [];
    public info: InfoViewModel = new InfoViewModel();
    public tags: TagViewModel[] = [];
    public paths: PathViewModel[] = [];
    public security: SecurityViewModel[] = [];
    constructor(init?: Partial<OasGeneratorRequest>) {
        Object.assign(this, init);
    }
}


