<hr>
<section class="full-size-section">

    <div *ngIf=" oasgenRequest.selectedEntityVersions.length>0">
        <div class="row">
            <div class="col ">
                <h4 class="text-left">#</h4>
            </div>
            <div class="col-4">
                <h4  class="text-left">Entity</h4>
            </div>
            <div class="col-7">
                <h4  class="text-left">Attributes</h4>
            </div>
        </div>
        <hr>
        <div *ngFor="let item of oasgenRequest.selectedEntityVersions;index as i">
            <div class="row">
                <div class="col text-left">
                    {{i+1}}
                </div>
                <div class="col-4 text-left">
                    {{item.entity.name}}
                </div>
                <div class="col-7 text-left">
                    <a *ngIf="!item.showAttributes"  (click)="showAttribute(item)">Click to expand and select attributes<span class="icon-chevron-down"></span></a>
                    <a *ngIf="item.showAttributes"  (click)="hideAttribute(item)">Click to hide attributes<span *ngIf="item.showAttributes" class="icon-chevron-up"></span></a>
                </div>
            </div>
            <div class="row">
                <div class="col">

                </div>
                   <div class="col-11">
                    <table *ngIf="item.showAttributes" class="table table-bordered table-sm mt-3">
                        <caption>Attribute table</caption>
                        <thead>
                            <tr class="d-flex" >
                                <th class="col">
                                    <div class="checkbox">
                                        <input type="checkbox" [value]="item.id"
                                            (change)="onSelectAllCheckboxChange($event)" />
                                    </div>
                                </th>
                                <th class="col-3" scope="col">Name</th>
                                <th class="col-7" scope="col">Description</th>
                                <th class="col" scope="col">Datatype</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="d-flex"  *ngFor="let attribute of item.attributes">
                                <td class="col">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox" [(ngModel)]="attribute.isSelected"
                                                [value]="attribute" (change)="onSelectAttributeCheckBoxChange($event)">
                                        </label>
                                    </div>
                                </td>
                                <td class="col-3">{{attribute.name}}</td>
                                <td class="col-7">{{attribute.description}}</td>
                                <td class="col">{{attribute.entityAttributeDataType.name}}</td>
                            </tr>
                        </tbody>
                    </table>
                   </div>
            </div>
            <hr>

        </div>
    </div>
    <div *ngIf=" oasgenRequest.selectedEntityVersions.length==0">
        <div class="row">
            <div class="col">
                <h5>Please select Entities in previous step to view their attributes here.</h5>
            </div>
        </div>
    </div>


</section>
