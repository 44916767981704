import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER,CUSTOM_ELEMENTS_SCHEMA, Injector, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CalAngularModule } from '@cvx/cal-angular';
import { NextPage } from '@cvx/nextpage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AppConfig } from './oas-generator/app-config.service';
import { OASGeneratorService } from './oas-generator/services/curated-api-service';
import { OasGeneratorComponent } from './oas-generator/oas-generator.component';

import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule} from '@angular/cdk/scrolling';
import { MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatSelectModule} from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatTableModule} from '@angular/material/table';
import { MatCheckboxModule} from '@angular/material/checkbox';

import { MatIconModule} from '@angular/material/icon';
import { MatExpansionModule} from '@angular/material/expansion';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatStepperModule} from '@angular/material/stepper';
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import { Step1EntitiesSelectComponent } from './oas-generator/steps/step1-entities-select/step1-entities-select.component';
import { Step2AttributesSelectComponent } from './oas-generator/steps/step2-attributes-select/step2-attributes-select.component';
import { Step4OASPreviewDownloadComponent } from './oas-generator/steps/step4-oas-preview-download/step4-oas-preview-download.component'
import { OasGeneratorRequestBuilderService } from './oas-generator/services/stepper-data-service';
import { Step3OasBuilderComponent } from './oas-generator/steps/step3-oas-builder/step3-oas-builder.component';
import { InfoComponent } from './oas-generator/steps/step3-oas-builder/info/info.component';
import { TagComponent } from './oas-generator/steps/step3-oas-builder/tags/tag.component';
import { PathComponent } from './oas-generator/steps/step3-oas-builder/paths/paths.component';
import { SecurityComponent } from './oas-generator/steps/step3-oas-builder/security/security.component';
import { SafeUrlPipe } from './oas-generator/pipes/safe-url.pipe';
import { EditorComponent } from './oas-generator/steps/step4-oas-preview-download/editor/editor.component';
import { FormGenerateComponent } from './playbook-generator/form-generate.component';
import { LoadFormComponent } from './playbook-generator/load-form/load-form.component';
import { LoadingOverlayComponent } from './oas-generator/loading-overlay/loading-overlay.component';


export function initializeApp(appConfig: AppConfig) {
    return (): Promise<any> => {
      return appConfig.load();
    }
  }

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        OasGeneratorComponent,
        Step1EntitiesSelectComponent,
        Step2AttributesSelectComponent,
        Step3OasBuilderComponent,
        Step4OASPreviewDownloadComponent,
        InfoComponent,
        TagComponent,
        PathComponent,
        SecurityComponent,
        SafeUrlPipe,
        EditorComponent,
        FormGenerateComponent,
        LoadFormComponent,
        LoadingOverlayComponent,
        ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        HttpClientModule,
        NextPage,
        ReactiveFormsModule,
        CalAngularModule.forRoot(`assets/config.json`),
        CommonModule,
        BrowserAnimationsModule,
        ScrollingModule,
        MatInputModule ,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatIconModule,
        MatExpansionModule,
        MatTooltipModule,
        MatStepperModule,
        MatProgressSpinnerModule
    ],
    providers: [
        AppConfig,
        {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          deps: [AppConfig],
          multi: true
        },
        OASGeneratorService,
        OasGeneratorRequestBuilderService
    ],
    schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private injector: Injector) {}
}
