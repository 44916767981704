import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { Entity } from 'src/app/oas-generator/models/curated-Api/curated-api-entity.model';
import { OASGeneratorService } from '../../../oas-generator/services/curated-api-service';
import { EntityVersion } from 'src/app/oas-generator/models/curated-Api/curated-api-entity-version.model';
import { OasGeneratorRequestBuilderService } from '../../../oas-generator/services/stepper-data-service';
import { OasGeneratorRequest } from 'src/app/oas-generator/models/oas-generator.model';
import { EntityVersionAttribute } from 'src/app/oas-generator/models/curated-Api/curated-api-attribute.model';
import { EntityVersionViewModel } from 'src/app/oas-generator/models/oas-generator-view.model';

@Component({
    selector: 'app-step1-entities-select',
    templateUrl: './step1-entities-select.component.html',
    styleUrls: ['./step1-entities-select.component.scss'],
})
export class Step1EntitiesSelectComponent implements OnInit {
    entities: Entity[] = [];
    entityVersions!: EntityVersion[];
    selectEntityControl = new FormControl('');
    selectEntityVersionControl = new FormControl('');
    filteredEntities!: Observable<Entity[]>;
    filteredVersions!: Observable<EntityVersion[]>;
    isLoading = false;
    oasgenRequest:OasGeneratorRequest = new OasGeneratorRequest();
    constructor(private _oasGenService: OASGeneratorService, private _requestBuilder:OasGeneratorRequestBuilderService)
    {
        _requestBuilder.getData().subscribe(res => {
          this.oasgenRequest = res;
        });
    }
    async ngOnInit() {
        this.entities = await this._oasGenService.getEntities('');
        this.filteredEntities = this.selectEntityControl.valueChanges.pipe(
            startWith(''),
            map((value) => this._filter(value ?? ''))
        );
    }

    private _filter(value: string): Entity[] {
        const filterValue = String(value).toLowerCase();
        return this.entities.filter((option) =>
            option.name.toLowerCase().includes(filterValue)
        );
    }

    async onEntitySelect(entity: Entity) {
        this.isLoading = true;
        this.entityVersions = await this._oasGenService.getEntityVersionsByEntityId(entity.id);
        this.filteredVersions =
            this.selectEntityVersionControl.valueChanges.pipe(
                startWith(''),
                map((value) => this.entityVersions)
            );
        this.isLoading = false;
    }

    getOptionText(option: Entity) {
        return option?.name;
    }
    async onVersionSelect(option:EntityVersion) {
        this.isLoading = true;
        let attributes = await this.getEntityVersionAttributes(option.id);
        let entityViewModel = new EntityVersionViewModel();
        Object.assign(entityViewModel,option);
        Object.assign(entityViewModel.attributes, attributes);
        this.oasgenRequest.selectedEntityVersions.push(entityViewModel);
        this._requestBuilder.setData(this.oasgenRequest);
        this.isLoading = false;
    }
    async getEntityVersionAttributes(
        entityVersionId: number
    ): Promise<EntityVersionAttribute[]> {
        return await this._oasGenService.getEntityAttributesbyEntityVersionId(
            entityVersionId
        );
    }
    RemoveEntityVersionEntry(entityVersionId: number)
    {
        this.isLoading = true;
        this.oasgenRequest.selectedEntityVersions = this.oasgenRequest.selectedEntityVersions.filter(entityVersion=> entityVersion.id!=entityVersionId);
        this._requestBuilder.setData(this.oasgenRequest);
        this.isLoading = false;
    }
}
