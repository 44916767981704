<section id="column1" npSetLayout [pageLayout]="PageLayout.Layout12">


<mat-horizontal-stepper linear #stepper style="margin: 2vw;margin-top: 2vh;">
    <mat-step>
        <ng-template matStepLabel>Select Entities</ng-template>
        <mat-divider></mat-divider>

        <app-step1-entities-select></app-step1-entities-select>
        <div class="float-right">
            <button mat-raised-button matStepperNext>next &raquo;</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Select Attributes</ng-template>
        <mat-divider></mat-divider>
        <div class="float-left">
            <button mat-button  matStepperPrevious> &laquo;  </button>
        </div>
        <div class="float-right">
            <button mat-button matStepperNext > &raquo;</button>
        </div>
        <mat-divider></mat-divider>
        <app-step2-attributes-select></app-step2-attributes-select>
        <mat-divider></mat-divider>
        <div class="float-left">
            <button mat-button  matStepperPrevious> &laquo; back </button>
        </div>
        <div class="float-right">
            <button mat-button matStepperNext >next &raquo;</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>Build OAS File</ng-template>
        <mat-divider></mat-divider>
        <div class="float-left">
            <button mat-button  matStepperPrevious> &laquo;  </button>
        </div>
        <div class="float-right">
            <button mat-button matStepperNext > &raquo;</button>
        </div>
        <app-step3-oas-builder></app-step3-oas-builder>
        <mat-divider></mat-divider>
        <div class="float-left">
            <button mat-button  matStepperPrevious> &laquo; back </button>
        </div>
        <div class="float-right">
            <button mat-button matStepperNext >next &raquo;</button>
        </div>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>OAS Preview/Download</ng-template>
        <mat-divider></mat-divider>
        <app-step4-oas-preview-download></app-step4-oas-preview-download>
        <mat-divider></mat-divider>
        <div class="float-left">
            <button mat-button  matStepperPrevious> &laquo;back</button>
        </div>
        <div class="float-right">
            <button mat-button (click)="stepper.reset()">reset</button>
        </div>
    </mat-step>
</mat-horizontal-stepper>
</section>
