import { Component } from '@angular/core';
import { OasGeneratorRequest } from 'src/app/oas-generator/models/oas-generator.model';
import { OasGeneratorRequestBuilderService } from '../../services/stepper-data-service';
import { EntityAttributeDataType } from 'src/app/oas-generator/models/curated-Api/curated-api-entity-attribute-data-type.model';
import { Components, Contact, Info, OAuth2, OasFile, OperationObject, Parameter, PathItem, SchemaObject, SchemaObjectProperty, SchemaObjectPropertyDefinition } from 'src/app/oas-generator/models/oas-generator-print.model';
import 'ace-builds';
import 'ace-builds/src-noconflict/mode-yaml';
import YAML from 'yaml';
import { SecurityViewModel } from 'src/app/oas-generator/models/oas-generator-security.model';

@Component({
  selector: 'app-step4-oas-preview-download',
  templateUrl: './step4-oas-preview-download.component.html',
  styleUrls: ['./step4-oas-preview-download.component.scss']
})
export class Step4OASPreviewDownloadComponent {
    private _isBusy: boolean = false;
    public get isBusy(): boolean {
        return this._isBusy;
    }

    private _errorMessage: string = '';
    public get errorMessage(): string {
        return this._errorMessage;
    }
    oasgenRequest!: OasGeneratorRequest;
    oasgenYAMLData!: OasFile;
    yamlInputData = '';

    constructor(
        private _requestBuilder: OasGeneratorRequestBuilderService
    ) {
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
            this.oasgenYAMLData = this.getYAMLDataforOasGen();
            this.yamlInputData = YAML.stringify(this.oasgenYAMLData);
        });
    }

    getYAMLDataforOasGen():OasFile
    {
      let file: OasFile = new OasFile();
      file.openapi = "3.0.1";
      file.info = new Info();
      file.info.title = this.oasgenRequest.info.name;
      file.info.description = this.oasgenRequest.info.description;
      file.info.version = this.versionConvert(this.oasgenRequest.info.version);
      file.info.contact = new Contact();
      file.info.contact.name = this.oasgenRequest.info.supportTeam;
      file.info.contact.email = this.oasgenRequest.info.contactEmail;
      file.servers = [{url:"https://apim.chevron.com"}];
      if(this.oasgenRequest.tags.length>0)
      {
        this.populateTags(file);
      }

      if(this.oasgenRequest.paths.length>0)
      {
        this.populatePaths(file);
      }

      if(this.oasgenRequest.selectedEntityVersions.length>0)
      {
         this.populateComponents(file);
      }
      return file;
    }
    
    private populateComponents(file: OasFile) {
        file.components = new Components;
        file.components.schemas = {};
        for (let entityVersion of this.oasgenRequest.selectedEntityVersions) {
            let propModel = new SchemaObject;
            propModel.type = "object";
            propModel.properties = [];
            for (let attribute of entityVersion.attributes) {
                if (attribute.isSelected) {
                    propModel.properties.push({
                        [attribute.name]: {
                            type: this.typeConvert(attribute.entityAttributeDataType).name,
                            description: attribute.description,
                            format: this.typeConvert(attribute.entityAttributeDataType).format
                        } as SchemaObjectPropertyDefinition
                    } as SchemaObjectProperty);
                }
            }

            file.components.schemas[entityVersion.entity.name] = propModel;
        }
        file.components.responses = {};
        file.components.responses["Success"] = { description: "Success", content: { ["application/json"]: { type: "string", default: "200 Success" } } };
        file.components.responses["Created"] = { description: "Created", content: { ["application/json"]: { type: "string", default: "201 Created" } } };
        file.components.responses["BadRequest"] = { description: "BadRequest", content: { ["application/json"]: { type: "string", default: "400 Bad Request" } } };
        file.components.responses["Unauthorized"] = { description: "Unauthorized", content: { ["application/json"]: { type: "string", default: "401 Unauthorized" } } };
        file.components.responses["Forbidden"] = { description: "Forbidden", content: { ["application/json"]: { type: "string", default: "403 Forbidden" } } };
        file.components.responses["InternalServerError"] = { description: "InternalServerError", content: { ["application/json"]: { type: "string", default: "500 Internal Server Error" } } };

        if (this.oasgenRequest.security.length > 0) {
            this.populateSecurity(file);
        }
    }

    private populateSecurity(file: OasFile) {
        file.components.securitySchemes = {};

        let oauth = new OAuth2();
        oauth.type = "oauth2";
        oauth.flows = {};
        for (let sec of this.oasgenRequest.security) {
            if (sec.securityType == "OAuth") {

                this.setOauthSection(sec, oauth);

            }
            if (sec.securityType === "ApiKey") {

                this.setApiKeySection(sec, file);
            }
        }

        if (this.oasgenRequest.security.find(a => a.securityType == "OAuth")) {
            file.components.securitySchemes.oauth2 = oauth;
        }
    }

    private setApiKeySection(sec: SecurityViewModel, file: OasFile) {
        if (sec.apiKeyType === "Authorization") {
            file.components.securitySchemes.apiTokenHeader = { type: "apiKey", name: "Authorization", description: "OAuth 2.0 bearer token", in: "header" };
        }
        if (sec.apiKeyType === "Ocp-Apim-Subscription-Key") {
            file.components.securitySchemes.apiKeyHeader = { type: "apiKey", name: "Ocp-Apim-Subscription-Key", in: "header" };
        }
    }

    private setOauthSection(sec: SecurityViewModel, oauth: OAuth2) {
        if (sec.oauthType === "clientCredentials") {
            oauth.flows["clientCredentials"] = { tokenUrl: "https://login.microsoftonline.com/chevron.onmicrosoft.com/oauth2/token", scopes: [{ read: "for API read access" }] };
        }
        if (sec.oauthType === "authorizationCode") {
            oauth.flows["authorizationCode"] = { authorizationUrl: "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26/oauth2/v2.0/authorize", tokenUrl: "https://login.microsoftonline.com/chevron.onmicrosoft.com/oauth2/token", scopes: [{ read: "for API read access" }] };
        }
    }

    private populatePaths(file: OasFile) {
        file.paths = {};
        for (let path of this.oasgenRequest.paths) {
            let operationObject = new OperationObject();
            operationObject.description = path.description;
            operationObject.summary = path.summary;
            operationObject.operationId = path.operationId;
            if (path.tags.length > 0) {
                operationObject.tags = path.tags.map(a => a.name);
            }
            if (path.parameters.length > 0) {
                operationObject.parameters = [];
                for (let param of path.parameters) {
                    let parameter = new Parameter();
                    parameter.name = param.name;
                    parameter.in = param.in;
                    parameter.description = param.description;
                    if (param.required) {
                        parameter.required = true;
                    }
                    parameter.schema = { pattern: "/*", type: "string" };
                    operationObject.parameters.push(parameter);
                }

            }
            operationObject.responses = [];
            operationObject.responses.push({
                ["200"]: { $ref: "#/components/responses/Success" },
                ["201"]: { $ref: "#/components/responses/Created" },
                ["400"]: { $ref: "#/components/responses/BadRequest" },
                ["401"]: { $ref: "#/components/responses/Unauthorized" },
                ["403"]: { $ref: "#/components/responses/Forbidden" },
                ["500"]: { $ref: "#/components/responses/InternalServerError" },
            });
            let pathItem: PathItem = {
                [path.httpAction]: operationObject
            };


            file.paths[path.endpoint] = pathItem;
        }
    }

    private populateTags(file: OasFile) {
        file.tags = [];
        for (let tag of this.oasgenRequest.tags) {
            file.tags.push({ name: tag.name, description: tag.description });
        }
    }

    downloadClicked(): void {
        try {

            let element = document.createElement('a');
            element.setAttribute(
                'href',
                'data:text/plain;charset=utf-8,' +
                    encodeURIComponent(this.yamlInputData)
            );
            element.setAttribute('download', 'oas.yml');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        } catch (err) {
            this._errorMessage =
                'Something went wrong, please try again later. If the problem persist, please contact the technical support team.';
            throw err;
        } finally {
            this._isBusy = false;
        }
    }

    versionConvert(version: string){
        if (version == "" || version == undefined){
            return "";
        }
        if(version[0].startsWith("v")){
            return version.substring(1) + ".0";
        }
        return version;
    }

    typeConvert(type: EntityAttributeDataType): EntityAttributeDataType{
        let returnData = {name: '', format: '', description: type.description, id: type.id}
        switch(type.name){
            case "int64":
                returnData.name = "integer"
                returnData.format = "int64"
                break;
            case "int32":
                returnData.name = "integer"
                returnData.format = "int32"
                break;
            case "int16":
                returnData.name ="integer"
                returnData.format= "int16"
                break;
            case "decimal":
                returnData.name= "number"
                returnData.format= "decimal"
                break;
            case "time":
                returnData.name= "string"
                returnData.format= "time"
                break;
            case "datetime":
                returnData.name= "string"
                returnData.format= "date-time"
                break;
            case "uniqueidentifier":
                returnData.name= "string"
                returnData.format= "uuid"
                break;
            case "binary":
                returnData.name= "string"
                returnData.format= "binary"
                break;
            case "date":
                returnData.name= "string"
                returnData.format= "date"
                break;
            case "double":
                returnData.name= "number"
                returnData.format= "double"
                break;
            case "float":
                returnData.name= "number"
                returnData.format= "float"
                break;
            case "byte":
                returnData.name= "string"
                returnData.format= "byte"
                break;
            case "char":
                returnData.name= "string"
                returnData.format= ""
                break;
            case "string":
                returnData.name= "string"
                break;
            case "number":
                returnData.name= "number"
                break;
            case "boolean":
                returnData.name= "boolean"
                break;
            case "integer":
                returnData.name= "integer"
                break;
            case "array":
                returnData.name= "array"
                break;
            case "object":
                returnData.name= "object"
                break;
            default:{
                break;
            }
        }
        return returnData;
    }
}
