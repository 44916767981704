export class OasFile {
    openapi!: "3.0.1";
    info!:       Info;
    servers!:    Server[];
    security!:   Security[];
    tags!:       Tag[];
    paths!    :  Paths;
    components!: Components;
}
export class Info {
    description!: string;
    version!:     string;
    title!:       string;
    contact!:     Contact;
}
export class Contact {
    name!:  string;
    url!:   string;
    email!: string;
}

export class Paths {
    [key: string]: PathItem
}

export class PathItem {
    [key: string]: OperationObject;
}

export class OperationObject {
    tags!:        string[];
    summary!:     string;
    description!: string;
    operationId!: string;
    parameters!:  Parameter[];
    responses!:   ParameterResponse[];
}
export class Parameter {
    name!:        string;
    in!:          string;
    description!: string;
    required!:    boolean;
    schema!:      ParameterSchema;
}
export class ParameterResponse{
    [key: string]: ParameterResponseRef
}
export class ParameterResponseRef{
    $ref!:string;
}
export class ParameterSchema {
    type!:  string;
    pattern!:string;
}
export class Security {
    apiKeyHeader!: any[];
}

export class Server {
    url!: string;
}
export class Components {
    schemas!:         Schemas;
    responses!:   ComponentResponseSchema;
    securitySchemes!: SecuritySchemes;
}

export class ComponentResponseSchema{
    [key: string]: Response
}

export class Response {
    description!: string;
    content!:  Content ;
}

export class Content{
    [key: string]: Schema ;
}
export class Schema{
    type!:string;
    default!:string;
}
export class Tag {
    name!:        string;
    description!: string;
}

export class Schemas {
    [key: string] : SchemaObject
}

export class SchemaObject{
    type!:string;
    description!:string;
    required!:string[];
    properties!:SchemaObjectProperty[]
}

export class SchemaObjectProperty
{
    [key: string] : SchemaObjectPropertyDefinition
}
export class SchemaObjectPropertyDefinition
{
    type!:string;
    description!:string;
    format!:string;
    example!:string;
}
export class SecuritySchemes {
    [key: string] : Object;
}


export class APIKeyAuth {
    type!: string;
    name!: string;
    in!:   string;
    description?:string;
}

export class OAuth2 {
    type!:  string;
    flows!: Flow;
}
export class Flow {
    [key: string] : Object;
}

export class AuthorizationCode{
    tokenUrl!: string;
    authorizationUrl!:string;
    scopes!:   Scopes[];
}
export class Implicit {
    authorizationUrl?: string;
    scopes?:           Scopes[];
}
export class ClientCredentials {
    tokenUrl!: string;
    scopes!:   Scopes[];
}

export class Scopes {
    read?:  string;
    write?: string;
}


