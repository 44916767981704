import { Component } from '@angular/core';
import { PageComponent } from "../oas-generator/page.component";
import { FormService } from './services/form-generator.service';
import { Template } from './models/template.model';

@Component({
  selector: 'app-form-generate',
  templateUrl: './form-generate.component.html',
  styleUrls: ['./form-generate.component.scss']
})

export class FormGenerateComponent extends PageComponent {
  isBusy: boolean = false;
  loadingMessage: string = "";
  errorMessage: string = "";
  templates!: Template[];
  selectedTemplate!: string;

  constructor(public formService: FormService) {
    super();
  }
  
  override async ngOnInit() {
    this.isBusy = true;
    this.loadingMessage = "Loading templates from database"
    let retryAttemptsRemaining = 3;
    let currentRetryAttempt = 0;
    let isDataLoaded = false;
    while (retryAttemptsRemaining > 0 && !isDataLoaded) {
      retryAttemptsRemaining = retryAttemptsRemaining - 1;
      try {
        this.templates = await this.formService.getAllTemplates()
        isDataLoaded = true;
      } catch (error: any) {
        let responseCode = error?.response?.data?.Status ?? 0;
        if (responseCode != 500 && responseCode != 503) {
          retryAttemptsRemaining = 0;
        }else{
          currentRetryAttempt = currentRetryAttempt + 1;
          this.loadingMessage = "Backend is currently warming up. Retry attempt " + currentRetryAttempt;
        }
      }
    }
    if(this.templates == undefined || this.templates.length == 0){
      this.errorMessage = "Failed to load templates. Backend may still be warming up so please try again shortly. If the problem persist, please contact the technical support team."
    }
    this.isBusy = false;
  }

  async loadTemplateFormData(event: any) {
    if (event.target.value != 0) {
      this.selectedTemplate = event.target.value;
    }
  }
}
