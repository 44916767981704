import { Component } from '@angular/core';
import { OasGeneratorRequestBuilderService } from '../../../oas-generator/services/stepper-data-service';
import { OasGeneratorRequest } from 'src/app/oas-generator/models/oas-generator.model';
import { EntityVersionViewModel } from 'src/app/oas-generator/models/oas-generator-view.model';

@Component({
    selector: 'app-step2-attributes-select',
    templateUrl: './step2-attributes-select.component.html',
    styleUrls: ['./step2-attributes-select.component.scss'],
})
export class Step2AttributesSelectComponent {
    isLoading = false;
    oasgenRequest!: OasGeneratorRequest;

    constructor(
        private _requestBuilder: OasGeneratorRequestBuilderService
    ) {
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
        });
    }

    showAttribute(item: EntityVersionViewModel)
    {
        let data=this.oasgenRequest.selectedEntityVersions.findIndex(a=>a.id==item.id);
        this.oasgenRequest.selectedEntityVersions[data].showAttributes=true;
        this._requestBuilder.setData(this.oasgenRequest);
    }

    hideAttribute(item: EntityVersionViewModel)
    {
        let data=this.oasgenRequest.selectedEntityVersions.findIndex(a=>a.id==item.id);
        this.oasgenRequest.selectedEntityVersions[data].showAttributes=false;
        this._requestBuilder.setData(this.oasgenRequest);
    }


    onSelectAllCheckboxChange(e: any) {
        this.oasgenRequest.selectedEntityVersions
        .find((a) => a.id == e.target.value)
        ?.attributes.forEach((val) => {
            val.isSelected = e.target.checked;
        });
        this._requestBuilder.setData(this.oasgenRequest);
    }

    onSelectAttributeCheckBoxChange(e: any) {
        this._requestBuilder.setData(this.oasgenRequest);
    }
}
