import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { HttpAction, PathViewModel } from "src/app/oas-generator/models/oas-generator-path.model";
import { OasGeneratorRequest } from "src/app/oas-generator/models/oas-generator.model";
import { OasGeneratorRequestBuilderService } from "src/app/oas-generator/services/stepper-data-service";
import { TagViewModel } from "src/app/oas-generator/models/oas-generator-tag.model";

@Component({
    selector: 'paths',
    templateUrl: './paths.component.html',
    styleUrls: ['./paths.component.scss']
})

export class PathComponent{
    oasBuilderPathForm!: FormGroup;
    inputModel!: PathViewModel;
    showPaths: boolean = true;
    httpActions: HttpAction[] = HttpAction.options;
    required: string[] = ["yes", "no"];
    inOptions: string[] = ["header", "query", "path"];

    oasgenRequest!: OasGeneratorRequest;

    constructor(private fb: FormBuilder,
        private _requestBuilder: OasGeneratorRequestBuilderService) {
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
        });
    }

    ngOnInit(): void {
        const form: FormGroup = this.fb.group({
            paths: this.fb.array([])
        });

        form.controls.paths?.valueChanges.subscribe(value => {
            const pathRows = this.paths().controls;
            let currentPathViewModels: PathViewModel[] = [];
            pathRows.forEach((path) =>
            currentPathViewModels.push({
                httpAction: path.value.httpAction,
                summary: path.value.summary,
                description: path.value.description,
                operationId: path.value.operationId,
                endpoint: path.value.endpoint,
                parameters: path.value.parameters,
                tags: path.value.tags
            }));
            this.oasgenRequest.paths = currentPathViewModels;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        this.oasBuilderPathForm = form;
    }

    getGlobalTags(): TagViewModel[]{
        return this.oasgenRequest.tags;
    }

    paths() {
        return this.oasBuilderPathForm.controls['paths'] as FormArray;
      }

    addPath(): void{
        this.paths().push(
            this.fb.group({
                httpAction: this.fb.control(''),
                summary: this.fb.control(''),
                description: this.fb.control(''),
                operationId: this.fb.control(''),
                endpoint: this.fb.control(''),
                parameters: this.fb.array([]),
                tags: this.fb.array([])
            })
        );
    }

    removePath(idx: number): void{
        this.paths().removeAt(idx);
    }

    parameters(pathIndex: number) {
        return this.paths().at(pathIndex).get("parameters") as FormArray;
      }

    addParameter(pathIndex: number): void{
        this.parameters(pathIndex).push(
            this.fb.group({
                name: this.fb.control(''),
                in: this.fb.control(''),
                description: this.fb.control(''),
                required: this.fb.control('')
              })
        );
    }

    removeParameter(pathIndex: number, paramIndex: number): void{
        this.parameters(pathIndex).removeAt(paramIndex);
    }

    tags(pathIndex: number) {
        return this.paths().at(pathIndex).get("tags") as FormArray;
      }

    addTag(pathIndex: number): void{
        this.tags(pathIndex).push(
            this.fb.group({
                name: this.fb.control('')
              })
        );
    }

    removeTag(pathIndex: number, tagIndex: number): void{
        this.tags(pathIndex).removeAt(tagIndex);
    }

    show(): void {
        this.showPaths = !this.showPaths;
    }
}
