<app-loading-overlay [isBusy]="isBusy" [displayMessage]="loadingMessage"></app-loading-overlay>
<main class="container">
    <section class="w-100">
        <div *ngIf="errorMessage"  class="row">
            <div class="alert alert-danger w-100" role="alert">
                <span [textContent]="errorMessage"></span>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="templateSelect">
                        <span class="text-danger">*</span>
                        Select Template
                    </label>
                    <select  class="form-control" (change)="loadTemplateFormData($event)">
                        <option value="0">--Select template--</option>
                        <option *ngFor="let template of templates"
                                value={{template.name}}>
                            {{template.displayName}}
                        </option>
                    </select>

                </div>
            </div>
        </div>

        <div class="row">
            <div class="col" *ngIf="selectedTemplate">
               <app-load-form [template]="selectedTemplate"> </app-load-form>
            </div>
        </div>
    </section>
</main>
