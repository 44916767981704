export class SecurityViewModel {
    securityType!: string;
    oauthType!: string;
    apiKeyType!: string;

    constructor(init?: Partial<SecurityViewModel>) {
        Object.assign(this, init);
    }
}

