import { Component } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { OasGeneratorRequestBuilderService } from "src/app/oas-generator/services/stepper-data-service";
import { OasGeneratorRequest } from "src/app/oas-generator/models/oas-generator.model";
import { SecurityViewModel } from "src/app/oas-generator/models/oas-generator-security.model";

@Component({
    selector: 'security',
    templateUrl: './security.component.html',
    styleUrls: ['./security.component.scss']
})

export class SecurityComponent{
    constructor(private fb: FormBuilder,
        private _requestBuilder: OasGeneratorRequestBuilderService) {
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
        });
    }

    showSecurity: boolean = true;
    oasgenRequest!: OasGeneratorRequest;
    oasBuilderSecurityForm!: FormGroup;
    securityModel: SecurityViewModel = new SecurityViewModel();
    securityOptions: string[] = ["OAuth", "ApiKey"];
    oauthOptions: string[] = ["authorizationCode", "clientCredentials"];
    apiKeyOptions: string[] = ["Ocp-Apim-Subscription-Key", "Authorization"];

    ngOnInit(): void{
        const form: FormGroup = this.fb.group({
            security: this.fb.array([])
        });
        form.controls.security?.valueChanges.subscribe((value) => {
            const secRows = this.security().controls;
            let currentSecViewModels: SecurityViewModel[] = [];
            secRows.forEach(sec => currentSecViewModels.push({
                securityType: sec.value.security,
                oauthType: sec.value.flow,
                apiKeyType: sec.value.apiKeyType
            }));
            this.oasgenRequest.security = currentSecViewModels;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        this.oasBuilderSecurityForm = form;
    }

    security(){
        return this.oasBuilderSecurityForm.controls['security'] as FormArray;
    }

    addSecurity(): void{
        const form: FormGroup = this.fb.group({
            security: this.fb.control(''),
            flow: this.fb.control(''),
            apiKeyType: this.fb.control('')
        })
        this.security().push(form);
    }

    removeSecurity(idx : number): void{
        this.security().removeAt(idx);
    }

    isOauth(idx: number){
        const flow = this.security().at(idx).get("security")?.value;
        return flow == "OAuth";
    }

    isApiKey(idx: number){
        const flow = this.security().at(idx).get("security")?.value;
        return flow == "ApiKey";
    }

    show(): void {
        this.showSecurity = !this.showSecurity;
    }
}
