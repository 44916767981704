import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { OasGeneratorRequest } from 'src/app/oas-generator/models/oas-generator.model';
@Injectable()
export class OasGeneratorRequestBuilderService {

  public oasInputModel = new BehaviorSubject<OasGeneratorRequest>(new OasGeneratorRequest());

  setData(data: OasGeneratorRequest) {
    this.oasInputModel.next(data);
  }

  getData()
  {
    return this.oasInputModel;
  }

  //TO DO
  fixRequest(data: OasGeneratorRequest)
  {
    //Remove Duplicate Entries
    //if(data.selectedEntityVersions.length)
  }
}
