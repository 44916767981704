<div class="loading" *ngIf="isLoading">
    <mat-spinner></mat-spinner>
</div>
<hr>
<section class="full-size-section">

    <div class="row" style="width: 80vw; margin:0 auto;">
        <div class="col-4 mt-2">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Search Entity: </mat-label>
                    <input type="text" placeholder="search" matInput [formControl]="selectEntityControl"
                        [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)='onEntitySelect($event.option.value)'
                        [displayWith]="getOptionText">
                        <mat-option *ngFor="let option of filteredEntities|async" [value]="option">
                            {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </form>
        </div>

        <div class="col-2 mt-2">
            <form class="example-form">
                <mat-form-field class="example-full-width">
                    <mat-label>Select Entity Version: </mat-label>
                    <mat-select [formControl]="selectEntityVersionControl" (selectionChange)="onVersionSelect($event.value)">
                        <mat-option *ngFor="let option of filteredVersions | async" [value]="option">
                            {{option.version}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

        </div>
        <div class="col-6">
            <div *ngIf="oasgenRequest.selectedEntityVersions.length>0">
                <table npTable>
                    <thead>
                        <tr>
                            <th>Selected</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let ev of oasgenRequest.selectedEntityVersions">
                            <td>{{ ev.entity.name }} : Version {{ ev.version }}</td>
                            <td><button mat-icon-button type="button" class="delete-btn"  (click)="RemoveEntityVersionEntry(ev.id)"> <mat-icon aria-label="Remove this Entity"  >delete</mat-icon></button></td>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
</section>
