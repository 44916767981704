import { EntityAttributeDataType } from "./curated-Api/curated-api-entity-attribute-data-type.model";
import { Entity } from "./curated-Api/curated-api-entity.model";

export class EntityVersionViewModel {
    public id!: number;
    public entityId!: number;
    public version!: number;
    public description!: string;
    public qualifiedName!: string;
    public databaseName!: string;
    public datasourceLocation!: string;
    public formatType!: string;
    public digitalPlatformId!: number | null;
    public entity!: Entity;
    public showAttributes:boolean=false;
    public attributes: EntityVersionAttributeViewModel[]=[];
    constructor(init?: Partial<EntityVersionViewModel>) {
        Object.assign(this, init);
    }
}

export class EntityVersionAttributeViewModel
{
    public id!: number;
    public entityVersionId!: number;
    public entityAttributeDataTypeId!: number;
    public name!: string;
    public description!: string;
    public sourceValueEntityVersionAttributeId!: number ;
    public sourceDisplayEntityVersionAttributeId!: number ;
    public entityAttributeDataType!: EntityAttributeDataType;
    public isSelected:boolean=false;
    constructor(init?: Partial<EntityVersionAttributeViewModel>) {
        Object.assign(this, init);
    }
}


