import { Component } from "@angular/core";
import { PageComponent } from "../oas-generator/page.component";

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: [ './home.component.scss' ]
})
export class HomeComponent extends PageComponent {
    openState: boolean = false;

    onClick(){
        this.openState = !this.openState;
    }
}