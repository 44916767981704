import { Component, OnInit } from "@angular/core";
import { NavigationLocation, PageLayout, SearchType } from "@cvx/nextpage";

@Component({
    selector: 'page',
    template: ''
})
export abstract class PageComponent implements OnInit {
    NavigationLocation = NavigationLocation;
    PageLayout = PageLayout;
    SearchType = SearchType;

    ngOnInit(): void {  // NO SONAR
        // Intentionally blank
    }                   //NO SONAR
}
