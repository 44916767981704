import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OASGeneratorService } from "src/app/oas-generator/services/curated-api-service";
import { InfoViewModel, SupportTeam, Version } from "src/app/oas-generator/models/oas-generator-info.model";
import { OasGeneratorRequestBuilderService } from "src/app/oas-generator/services/stepper-data-service";
import { OasGeneratorRequest } from "src/app/oas-generator/models/oas-generator.model";

@Component({
    selector: 'info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss']
})

export class InfoComponent{
    constructor(
        private fb: FormBuilder,
        private _oasGenService: OASGeneratorService,
        private _requestBuilder: OasGeneratorRequestBuilderService) {
        _requestBuilder.getData().subscribe((res) => {
            this.oasgenRequest = res;
        });
    }

    oasgenRequest!: OasGeneratorRequest;
    apiInfoModel!: InfoViewModel;
    versionModel!: Version;
    apiId!: string;
    oasBuilderInfoForm!: FormGroup;
    showInfo: boolean = true;
    existsInMarketplace: boolean = false;
    versionSelected: boolean = false;
    isLoading!: boolean;
    isLoadingSupport!: boolean;
    existsInMarketplaceDropdown: string[] = ["yes", "no"];
    versionOptions: Version[] = []
    newVersion: boolean = false;

    ngOnInit(): void {
        const model = new InfoViewModel();
        const form: FormGroup = this.fb.group({
            apiId: [model.globalApiId, [Validators.required]],
            name: [model.name, [Validators.required]],
            description: [model.description, [Validators.required]],
            contactEmail: [model.contactEmail, [Validators.required]],
            version: [model.version, [Validators.required]],
            existsInMarketplace: [model.existsInMarketplace, [Validators.required]],
            supportTeam: [model.supportTeam, [Validators.required]]
        });

        form.controls.apiId?.valueChanges.subscribe((value) => {
            model.globalApiId = value;
            this.oasgenRequest.info.globalApiId = model.globalApiId;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.name?.valueChanges.subscribe((value) => {
            model.name = value;
            this.oasgenRequest.info.name = model.name;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.description?.valueChanges.subscribe((value) => {
            model.description = value;
            this.oasgenRequest.info.description = model.description;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.contactEmail?.valueChanges.subscribe((value) => {
            model.contactEmail = value;
            this.oasgenRequest.info.contactEmail = model.contactEmail;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.version?.valueChanges.subscribe((value) => {
            model.version = value;
            const currentVersion = this.versionOptions.find(version => version.globalApiVersionId = value);
            if (currentVersion?.version != null){
                this.oasgenRequest.info.version = currentVersion.version;
            }
            else{
                this.oasgenRequest.info.version = value;
            }
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.existsInMarketplace?.valueChanges.subscribe((value) => {
            model.existsInMarketplace = value;
            this.apiInfoModel = new InfoViewModel();
            this.apiId = '';
            this.oasgenRequest.info.existsInMarketplace = model.existsInMarketplace;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        form.controls.supportTeam?.valueChanges.subscribe((value) => {
            model.supportTeam = value;
            this.oasgenRequest.info.supportTeam = model.supportTeam;
            this._requestBuilder.setData(this.oasgenRequest);
        });
        this.oasBuilderInfoForm = form;
        this.apiInfoModel = model;
    }

    async onSubmit(event : Event): Promise<void>{
        this.isLoading = true;
        this.apiId = (<HTMLTextAreaElement>event.target).value;
        const curatedApiInfo = await this._oasGenService.getApi(this.apiId);
        Object.assign(this.apiInfoModel, curatedApiInfo);
        this.oasgenRequest.info.name = curatedApiInfo.name;
        this.oasBuilderInfoForm.controls.supportTeam.reset();
        this.oasBuilderInfoForm.controls.contactEmail.reset();
        this._requestBuilder.setData(this.oasgenRequest);
        this.isLoading = false;
        if (this.apiInfoModel.globalApiId != null){
            this.existsInMarketplace = true;
            this.versionOptions = await this._oasGenService.getApiVersions(this.apiId);
            this.versionOptions.push({
                version: "new",
                supportTeam: new SupportTeam(),
                globalApiVersionId: ""
        });
        }
    }

    async onVersionSubmit(event: Event): Promise<void>{
        const versionId = (<HTMLTextAreaElement>event.target).value;
        if (versionId == ""){
            this.newVersion = true;
            return;
        }
        let currentSupportTeam = {
            supportTeamEmail: "",
            supportTeamName: ""
        }
        this.versionModel = {
            version: "",
            globalApiVersionId: "",
            supportTeam: currentSupportTeam
        };
        this.newVersion = false;
        this.isLoadingSupport = true;
        this.versionSelected = true;
        let currentVersion = this.versionOptions.find(x => x.globalApiVersionId == versionId);
        if (currentVersion != null){
            this.oasgenRequest.info.supportTeam = currentVersion.supportTeam.supportTeamName;
            this.oasgenRequest.info.contactEmail = currentVersion.supportTeam.supportTeamEmail;
            this.versionModel.supportTeam.supportTeamName = currentVersion.supportTeam.supportTeamName;
            this.versionModel.supportTeam.supportTeamEmail = currentVersion.supportTeam.supportTeamEmail;
        }
        
        this._requestBuilder.setData(this.oasgenRequest);
        this.isLoadingSupport = false;
    }

    onExistsSubmit(event : Event): void{
        const exists = (<HTMLTextAreaElement>event.target).value;
        this.oasBuilderInfoForm.controls.apiId.reset();
        this.oasBuilderInfoForm.controls.version.reset();
        this.oasBuilderInfoForm.controls.description.reset();
        this.oasBuilderInfoForm.controls.name.reset();
        this.oasBuilderInfoForm.controls.supportTeam.reset();
        this.oasBuilderInfoForm.controls.contactEmail.reset();
        this.versionSelected = false;

        if (exists == "no"){
            this.existsInMarketplace = false;
        }
        else{
            this.existsInMarketplace = true;
        }
    }

    show(): void {
        this.showInfo = !this.showInfo;
    }
}
