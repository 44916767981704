<main class="container">
    <section class="w-100">
        <div class="row">
            <div class="col col-12">
                <h3 class="title" (click)="show()">
                    Paths
                    <span *ngIf="!showPaths" class="icon-expand-plus button-right" ></span>
                    <span *ngIf="showPaths" class="icon-expand-minus button-right"></span>
                </h3>
                <hr />
                <form *ngIf="showPaths" [formGroup]="oasBuilderPathForm">
                    <div formArrayName="paths">
                    <div class="endpoint" *ngFor="let path of paths().controls; let i=index;" [formGroupName]="i">
                        <div class="row">
                            <div class="col">
                                <h4 class="title">Endpoint
                                    <div class="button-right">
                                    <span class="icon-remove" (click)="removePath(i)"></span>
                                    </div>
                                </h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-3">
                                <label for="httpActionInput">
                                    <span class="text-danger">*</span>
                                    HttpAction
                                </label>
                                <select id="httpActionInput" class="form-control"
                                formControlName="httpAction">
                                <option value="">Select action</option>
                                <option *ngFor="let type of httpActions" [value]="type.value">
                                    {{ type.name }}
                                </option>
                                </select>
                            </div>
                            <div class="form-group col-md-9">
                                <label for="descriptionInput">
                                    <span class="text-danger">*</span>
                                    Description
                                </label>
                                <input id="descriptionInput" type="text" class="form-control"
                                    formControlName="description" placeholder="ex: description here" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-4">
                                <label for="endpointInput">
                                    <span class="text-danger">*</span>
                                    Endpoint
                                </label>
                                <input id="endpointInput" type="text" class="form-control"
                                    formControlName="endpoint" placeholder="ex: /resource" />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="operationIdInput">
                                    <span class="text-danger">*</span>
                                    Operation Id
                                </label>
                                <input id="operationIdInput" type="text" class="form-control"
                                    formControlName="operationId" placeholder="ex: get-resource" />
                            </div>
                            <div class="form-group col-md-4">
                                <label for="summaryInput">
                                    <span class="text-danger">*</span>
                                    Summary
                                </label>
                                <input id="summaryInput" type="text" class="form-control"
                                    formControlName="summary" placeholder="ex: summary here" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col parameter-header">
                                <h5 class="title">Parameters</h5>
                            </div>
                            <div formArrayName="parameters" class="parameter">
                                <div *ngFor="let param of parameters(i).controls; let j=index;" [formGroupName]="j">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label for="nameInput">
                                                Name
                                            </label>
                                            <input id="nameInput" type="text" class="form-control" formControlName="name"
                                                placeholder="ex: parameter name" />
                                        </div>
                                        <div class="col-md-2">
                                            <label for="inInput">
                                                In
                                            </label>
                                            <select id="inInput" class="form-control inline" formControlName="in">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of inOptions">
                                                    {{ item }}
                                                </option>
                                            </select>
                                        </div>
                                        <div class="col-md-5">
                                            <label for="descriptionInput">
                                                Description
                                            </label>
                                            <input id="descriptionInput" type="text" class="form-control" formControlName="description"
                                                placeholder="ex: parameter description" />
                                        </div>
                                        <div class="col-md-2 required">
                                            <div class="required">
                                                <label for="requiredInput" class="inline">
                                                    Required
                                                </label>
                                            </div>
                                            <div class="required-input">
                                                <select id="requiredInput" class="form-control inline" formControlName="required">
                                                    <option value="">Is required</option>
                                                    <option *ngFor="let req of required">
                                                        {{ req }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="inline-flex"(click)="removeParameter(i,j)">
                                                <span class="icon-remove inline"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                    <button mat-raised-button  class="button-right mat-focus-indicator mat-button-base mat-primary ng-star-inserted" style="width:150px !important;" (click)="addParameter(i)">
                                        add parameter
                                        <span class="icon-expand-add icon-expand-plus"></span>
                                    </button>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        <div class="row">
                            <div class="col parameter-header">
                                <h5 class="title">Tags</h5>
                            </div>
                            <div formArrayName="tags" class="parameter">
                                <div *ngFor="let tag of tags(i).controls; let j=index;" [formGroupName]="j">
                                    <div class="row">
                                        <div class="col-md-4 required">
                                            <div class="required-input">
                                                <select id="tagInput" class="form-control inline" formControlName="name">
                                                    <option value="">Select</option>
                                                    <option *ngFor="let item of getGlobalTags()" [value]="item.name">
                                                        {{ item.name }}
                                                    </option>
                                                </select>
                                            </div>
                                            <div class="inline-flex"(click)="removeTag(i,j)">
                                                <span class="icon-remove inline"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                    <button mat-raised-button  class="button-right mat-focus-indicator mat-button-base mat-primary ng-star-inserted" style="width:150px !important;" (click)="addTag(i)">
                                        add tag
                                        <span class="icon-expand-add icon-expand-plus"></span>
                                    </button>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <button mat-raised-button class="button-right mat-focus-indicator mat-button-base mat-primary ng-star-inserted" (click)="addPath()">
                            add
                            <span class="icon-expand-add icon-expand-plus"></span>
                        </button>
                        </div>
                    </div>
                </form>
                <hr />
            </div>
        </div>
    </section>
</main>
