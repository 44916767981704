<!-- Header -->
<np-header>
    <app-user-bar></app-user-bar>
    <!-- Search -->
    <np-search [searchType]="SearchType.Global"></np-search>
    <span id="hallmarkspan" tourAnchor="hallmark"></span>
    <!-- Global (top menu) Navigation -->
    <np-menu [location]="NavigationLocation.Global"></np-menu>
</np-header>

<!-- Site-Specific Navigation -->
<np-menu [location]="NavigationLocation.SiteSpecific"></np-menu>

<!-- Main Content -->
<div class="outlet">
    <router-outlet></router-outlet>


</div>

<!-- Footer -->
<np-footer
    [contentContactName]="'API & Integrations Team'"
    [contentContactUrl]="'mailto:APES-OrangutanTeam@chevron.com'"
    [technicalContactName]="'API & Integrations Team'"
    [technicalContactUrl]="'mailto:APES-OrangutanTeam@chevron.com'">
</np-footer>
